import "normalize.css"
import "./src/styles/global.css"
import "./src/styles/fonts.css"
// import "prismjs/themes/prism.css"
// import "prismjs/themes/prism-coy.min.css"
import "./src/styles/my-prism-theme.css"
// import { startFullStory } from './src/components/fullStory' // suggested by ChatGPT
//import './src/components/fullStory'

// gatsby-browser.js
export const shouldUpdateScroll = ({
    routerProps: { location },
    prevRouterProps,
    getSavedScrollPosition,
  }) => {

    // added this to fix the case when clicking links from search results
    // works but doesn't work when hash startes with number /tutorials/integrations/contentful-app/#5-content-model
    // if (location.hash) {
    //   // Delay scrolling to the hash to ensure the page has rendered
    //   setTimeout(() => {
    //       const hashElement = document.querySelector(location.hash);
    //       if (hashElement) {
    //           hashElement.scrollIntoView();
    //       }
    //   }, 50); // You might need to adjust this delay
    //   return false;
    // }


    if (location.hash) {
      setTimeout(() => {
          // Check if the hash starts with a number and adjust the selector accordingly
          const startsWithNumber = location.hash.match(/^#(\d)/);
          let selector;
          if (startsWithNumber) {
              // If hash starts with a number, use CSS escaping
              selector = `#\\3${startsWithNumber[1]} ${location.hash.slice(2)}`;
          } else {
              // For other cases, use the hash as is
              selector = location.hash;
          }

          const hashElement = document.querySelector(selector);
          if (hashElement) {
              hashElement.scrollIntoView();
          }
      }, 50); // Adjust this delay as needed
      return false;
  }    


    // Check if we're navigating to another page
    if (prevRouterProps) {

      // Check if it's a same-page navigation (hash change)
      if (location.pathname === prevRouterProps.location.pathname) {
        // Same page navigation (hash change), don't scroll to top
        return true;
      }
  
      // Different page navigation
      if (location.action === 'PUSH') {
        // Delay scrolling to top to ensure the page has rendered
        window.setTimeout(() => window.scrollTo(0, 0), 50);
      } else {
        // Use the saved scroll position for back/forward navigation
        const savedPosition = getSavedScrollPosition(location);
        if (savedPosition) {
          window.setTimeout(() => window.scrollTo(...savedPosition), 50);
        } else {
          window.setTimeout(() => window.scrollTo(0, 0), 50);
        }
      }
    } else {
      // Direct URL navigation (e.g., from an external link or refreshing the page)
      // Let the browser handle scrolling to the hash
      return true;
    }
  
    return false;
};

// works, need testing
// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   prevRouterProps,
//   getSavedScrollPosition,
// }) => {

//   // Check if there is a hash in the URL
//   if (location.hash) {
//     // Delay scrolling to the hash to ensure the page has rendered
//     setTimeout(() => {
//         const hashElement = document.querySelector(location.hash);
//         if (hashElement) {
//             hashElement.scrollIntoView();
//         }
//     }, 50); // You might need to adjust this delay
//     return false;
//   }

//   if (prevRouterProps) {
//       if (location.pathname === prevRouterProps.location.pathname) {
//           if (location.hash) {
//               window.setTimeout(() => {
//                   const element = document.querySelector(location.hash);
//                   if (element) {
//                       element.scrollIntoView();
//                   }
//               }, 50);
//           }
//           return false;
//       }
//       if (location.action === 'PUSH') {
//           window.setTimeout(() => window.scrollTo(0, 0), 50);
//       } else {
//           const savedPosition = getSavedScrollPosition(location);
//           if (savedPosition) {
//               window.setTimeout(() => window.scrollTo(...savedPosition), 50);
//           } else {
//               window.setTimeout(() => window.scrollTo(0, 0), 50);
//           }
//       }
//   }
//   return true;
// };



// suggested by ChatGPT
// export const onRouteUpdate = () => {
//   // Initialize FullStory when route updates
//   startFullStory();
// };

// this is OK, but page scrolls to top when open page with hash
// export const shouldUpdateScroll = ({
//     routerProps: { location },
//     prevRouterProps,
//     getSavedScrollPosition,
//   }) => {
//     // Check if we're navigating to another page or just changing hash on the same page
//     if (prevRouterProps && location.pathname === prevRouterProps.location.pathname) {
//       // Same page navigation (hash change), don't scroll to top
//       return false;
//     }
  
//     // Different page navigation
//     if (location.action === 'PUSH') {
//       window.setTimeout(() => window.scrollTo(0, 0), 50);
//     } else {
//       const savedPosition = getSavedScrollPosition(location);
//       window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 50);
//     }
  
//     return false;
// };
  


  

